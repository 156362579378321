import './styles.scss'

export default function LogoCisp() {

	return (
		<div>
			<div className='logo-cisp1'>
				<a href="/" aria-label="Logo">
					<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
						width="160px" viewBox="0 0 1710 600" enableBackground="new 0 0 1710 600" xmlSpace="preserve">

						{/* C */}
						<path
							fill="#FFFFFF"
							d=" M152.575348,200.247269 
				C200.494019,208.027069 233.581665,244.894516 242.997437,286.993011 
				C232.123489,286.993011 221.358582,287.145538 210.610168,286.793823 
				C209.312943,286.751373 207.621033,284.174377 206.933960,282.445435 
				C202.404587,271.047913 195.610397,261.255615 186.532730,253.072296 
				C175.585815,243.203918 162.739609,237.292664 148.184647,234.982956 
				C136.231781,233.086151 124.482666,232.820526 112.690384,236.585907 
				C100.381836,240.516129 89.359779,246.207397 79.521553,254.763077 
				C67.420891,265.286224 58.999069,278.146576 54.540150,293.197235 
				C48.305134,314.242950 49.242569,335.226593 58.177002,355.634979 
				C66.046684,373.611206 78.779976,387.066711 96.255936,395.544952 
				C118.839516,406.501099 142.206848,407.299103 165.510452,397.767181 
				C187.279343,388.863068 201.328201,372.336212 210.080475,350.863068 
				C210.583588,349.628693 212.413269,348.151276 213.654083,348.124817 
				C223.966492,347.905151 234.285614,348.000305 245.872711,348.000305 
				C242.766159,356.329987 240.210892,364.163147 236.956421,371.694122 
				C231.010971,385.452179 222.455841,397.620026 211.534439,407.782959 
				C195.904007,422.327942 177.919373,432.793396 156.379135,435.991913 
				C148.823990,437.113800 141.230347,438.875854 133.661346,438.840149 
				C108.788109,438.722870 85.874458,431.739563 65.601692,417.050690 
				C49.599293,405.455933 38.056580,390.131470 29.198910,372.623505 
				C23.331495,361.026062 19.644827,348.794128 17.990229,335.916840 
				C15.453422,316.173553 17.301907,297.010315 23.835947,278.062805 
				C30.293699,259.336456 40.530117,243.083069 54.907768,229.899399 
				C71.444305,214.736099 91.013779,204.938660 113.430023,200.850143 
				C126.413162,198.482132 139.234390,198.820343 152.575348,200.247269 
			z"
						/>

						{/* I */}
						<path
							fill="#FFFFFF"
							d="
	M413.000000,390.993683 
		C413.000000,337.524902 413.000000,284.554474 413.000000,231.185699 
		C398.393127,231.185699 383.984985,231.185699 369.290283,231.185699 
		C369.290283,220.388458 369.290283,209.983139 369.290283,199.287720 
		C409.950409,199.287720 450.684448,199.287720 491.709534,199.287720 
		C491.709534,209.611237 491.709534,220.016464 491.709534,230.822876 
		C477.384247,230.822876 462.976227,230.822876 448.286011,230.822876 
		C448.286011,288.952240 448.286011,346.684204 448.286011,404.815247 
		C462.607849,404.815247 477.015991,404.815247 491.710175,404.815247 
		C491.710175,415.612518 491.710175,426.017731 491.710175,436.712769 
		C451.048645,436.712769 410.314606,436.712769 369.289978,436.712769 
		C369.289978,426.387787 369.289978,415.982483 369.289978,405.176147 
		C383.616577,405.176147 398.024780,405.176147 413.000000,405.176147 
		C413.000000,400.362671 413.000000,395.927368 413.000000,390.993683 
	z"/>

						{/* S */}
						<path
							fill="#FFFFFF"
							d="
	M768.233521,415.267639 
		C756.241882,426.183777 742.397949,432.885620 726.980591,435.850586 
		C717.568848,437.660553 707.805359,439.261047 698.321045,438.741760 
		C677.544739,437.604156 658.169556,431.746948 641.556458,418.209229 
		C629.798523,408.627930 621.976379,396.848511 618.634033,382.132690 
		C617.631165,377.717499 617.242493,373.162811 616.516113,368.294250 
		C628.552917,368.294250 639.976257,368.294250 651.828674,368.294250 
		C653.126526,375.937744 655.555359,383.172302 661.437378,389.115326 
		C668.559692,396.311615 677.341614,400.244812 686.689514,402.751007 
		C702.460938,406.979462 717.989929,404.967438 732.465698,397.696106 
		C742.521667,392.644836 751.323425,385.860809 754.026062,373.787170 
		C757.578064,357.919495 751.400574,346.429199 735.288452,341.979736 
		C724.442627,338.984619 713.105774,337.607727 701.911011,336.073120 
		C684.310303,333.660309 666.585754,331.703888 650.418335,323.705444 
		C632.721069,314.950134 622.788696,300.446899 619.942200,280.868469 
		C618.123779,268.360962 618.788269,256.388062 623.711243,244.490738 
		C629.662048,230.109711 639.519897,219.564987 652.682373,211.767120 
		C671.937927,200.359482 693.101624,197.073334 714.841187,199.226440 
		C735.053223,201.228256 753.443665,208.616821 767.587219,224.116791 
		C776.640686,234.038467 781.364319,246.092224 783.806396,259.091309 
		C783.943970,259.823456 781.657959,261.792542 780.421997,261.865570 
		C775.108093,262.179535 769.762878,261.907440 764.433105,262.027100 
		C759.524231,262.137329 754.620544,262.475372 749.480408,262.724396 
		C745.141113,245.403946 732.230774,237.432312 715.822021,234.095367 
		C702.295227,231.344513 688.760620,232.254639 676.118286,238.105942 
		C661.258240,244.983658 651.967346,255.120316 654.173889,273.147125 
		C655.542786,284.330292 662.757935,292.046692 673.613525,294.825562 
		C684.529419,297.619843 695.765198,299.286255 706.936401,300.933838 
		C724.186340,303.477905 741.360535,306.292023 757.271729,313.816437 
		C773.353271,321.421295 785.117737,333.758850 788.667969,351.119843 
		C793.498962,374.744019 788.705627,396.548523 769.902832,413.603302 
		C769.409668,414.050629 768.955261,414.540649 768.233521,415.267639 
	z"/>

						{/* P */}
						<path
							fill="#FFFFFF"
							d="
	M915.000000,298.000000 
		C915.000000,265.526947 915.000000,233.553879 915.000000,201.000000 
		C916.800598,201.000000 918.397522,201.000000 919.994446,201.000000 
		C948.322693,201.000000 976.656372,200.668091 1004.977234,201.125259 
		C1021.800842,201.396835 1037.436279,206.144867 1050.703125,217.186127 
		C1065.232788,229.278351 1073.501831,244.925980 1076.983276,263.262665 
		C1081.551392,287.322601 1077.273438,309.634338 1062.989258,329.686584 
		C1052.285034,344.713440 1037.521729,353.834259 1019.433289,356.843903 
		C1010.089905,358.398499 1000.485657,358.676758 990.983765,358.916779 
		C977.851196,359.248474 964.703979,359.000000 951.167847,359.000000 
		C951.167847,384.961639 951.167847,410.699402 951.167847,436.717468 
		C939.038879,436.717468 927.300842,436.717468 915.000000,436.717468 
		C915.000000,390.679596 915.000000,344.589813 915.000000,298.000000 
	M1018.379761,321.001984 
		C1018.706177,320.964264 1019.080750,321.018158 1019.352173,320.875671 
		C1032.305542,314.072784 1040.478271,303.950867 1043.155029,289.153595 
		C1045.610718,275.579102 1043.151245,263.098755 1035.429077,252.201889 
		C1027.637329,241.206787 1015.870911,236.285004 1002.474182,236.061127 
		C986.819397,235.799530 971.156921,235.994034 955.497742,236.010025 
		C954.062561,236.011490 952.627563,236.153427 951.278625,236.225540 
		C951.278625,265.641205 951.278625,294.703552 951.278625,324.000000 
		C969.023499,324.000000 986.494507,324.198761 1003.955444,323.864960 
		C1008.509277,323.777863 1013.031311,322.031677 1018.379761,321.001984 
	z"/>

						{/* > */}
						<path
							fill="#FEFEFF"
							d="
	M1590.003174,253.978119 
		C1608.405762,267.068726 1626.524658,279.940979 1644.542236,292.953583 
		C1645.744385,293.821838 1646.183228,295.751892 1646.959473,297.197479 
		C1647.028931,297.326813 1646.966064,297.525757 1646.969360,297.692230 
		C1647.270996,312.735535 1647.058472,312.418060 1634.400513,320.892120 
		C1613.197388,335.086914 1592.301025,349.740112 1571.264648,364.184479 
		C1560.635498,371.482910 1549.982300,378.746582 1538.299072,386.737946 
		C1538.299072,382.047943 1538.298950,378.317627 1538.298950,374.587311 
		C1538.298950,366.926422 1538.490356,359.258575 1538.212524,351.607788 
		C1538.086914,348.144348 1539.362061,346.248291 1542.177856,344.407196 
		C1562.696045,330.992188 1583.102783,317.406586 1604.421753,303.294220 
		C1598.244385,298.978790 1592.417969,294.883179 1586.565430,290.824860 
		C1571.868530,280.633453 1557.228882,270.356812 1542.401367,260.359009 
		C1539.240723,258.227875 1538.121094,255.982559 1538.207275,252.226654 
		C1538.463379,241.077560 1538.299072,229.918823 1538.299072,217.496780 
		C1555.887573,229.917679 1572.791626,241.855225 1590.003174,253.978119 
	z"/>

						{/* 1 */}
						<path
							fill="#FFFFFF"
							d="
	M1431.882812,222.889359 
		C1421.280151,237.128754 1410.679077,251.369370 1400.072144,265.605530 
		C1399.089844,266.923645 1398.069336,268.213287 1396.788696,269.877350 
		C1387.060059,262.945923 1377.548950,256.169586 1367.863159,249.268723 
		C1383.870605,226.730789 1399.800049,204.310638 1415.720093,181.883759 
		C1422.907959,171.758118 1430.146606,161.667191 1437.205200,151.451996 
		C1438.975586,148.889984 1440.804565,147.651367 1444.099854,147.751419 
		C1452.870117,148.017715 1461.653809,147.841934 1470.660034,147.841934 
		C1470.660034,237.331711 1470.660034,326.243225 1470.660034,415.501587 
		C1458.139038,415.501587 1445.755981,415.501587 1432.759399,415.501587 
		C1432.759399,351.573517 1432.759399,287.807465 1432.787109,223.343231 
		C1432.504028,222.726456 1432.193481,222.807907 1431.882812,222.889359 
	z"/>

						{/* < */}
						<path
							fill="#FEFEFF"
							d="
	M1329.211426,227.189270 
		C1329.211426,236.664093 1329.055420,245.667908 1329.291626,254.661438 
		C1329.380493,258.047638 1328.309326,260.062775 1325.457397,261.920898 
		C1305.485352,274.933228 1285.625488,288.117676 1265.737183,301.258331 
		C1265.082397,301.690979 1264.508179,302.245300 1263.326294,303.206512 
		C1271.276978,308.715240 1278.827759,313.967255 1286.399658,319.188446 
		C1299.388184,328.144684 1312.360352,337.124969 1325.409058,345.992767 
		C1328.251953,347.924713 1329.405762,350.117615 1329.315674,353.707062 
		C1329.031982,365.009827 1329.211304,376.324280 1329.211304,388.450439 
		C1325.782959,386.236877 1322.900024,384.517273 1320.164917,382.587952 
		C1289.537354,360.983948 1258.930664,339.349976 1228.324951,317.714905 
		C1226.161865,316.185852 1223.923828,314.724152 1221.968628,312.956635 
		C1221.016479,312.095947 1220.151855,310.626862 1220.087036,309.389130 
		C1219.860474,305.067841 1219.850464,300.718781 1220.097168,296.399689 
		C1220.165405,295.202667 1221.134644,293.674408 1222.154663,292.969879 
		C1255.732666,269.774902 1289.363647,246.656433 1323.004761,223.552765 
		C1324.742798,222.359192 1326.639160,221.396393 1329.211304,219.886124 
		C1329.211304,222.570068 1329.211304,224.642105 1329.211426,227.189270 
	z"/>

						{/* CIRCLE */}
						<path
							fill="#3B55D6"
							d="
	M1172.006226,359.921204 
		C1168.296875,342.437073 1165.650391,325.269226 1165.969116,307.732819 
		C1166.142334,298.203247 1164.934937,288.520294 1166.230713,279.167084 
		C1168.669312,261.566650 1171.611328,243.941406 1175.895264,226.712250 
		C1183.307251,196.903015 1197.348511,169.965408 1215.115601,145.045898 
		C1221.480225,136.118988 1228.910889,127.942276 1235.988037,119.535255 
		C1251.710327,100.858742 1270.871582,86.217239 1291.334961,73.239883 
		C1311.715942,60.314816 1333.442627,50.210293 1356.835449,43.976273 
		C1368.468384,40.876156 1380.141968,37.907703 1391.872437,35.206226 
		C1395.844727,34.291428 1400.041992,34.164471 1404.146973,34.048119 
		C1419.412720,33.615429 1434.739014,32.362556 1449.937134,33.241432 
		C1469.995239,34.401360 1489.771729,38.019783 1509.151123,43.835953 
		C1526.114014,48.926903 1542.456055,55.370518 1558.071777,63.612762 
		C1574.901733,72.495895 1590.696045,82.989243 1605.040649,95.560417 
		C1611.247437,100.999718 1617.787842,106.137566 1623.454468,112.098976 
		C1639.653564,129.140945 1654.071533,147.552780 1665.673218,168.136047 
		C1677.535645,189.182465 1686.882324,211.227341 1692.198486,234.768661 
		C1695.215332,248.128830 1697.563843,261.746216 1698.788086,275.375793 
		C1700.076904,289.725220 1700.481567,304.266144 1699.795166,318.652893 
		C1698.914185,337.116241 1696.057495,355.496246 1690.270874,373.106659 
		C1685.191895,388.563873 1679.806396,404.063171 1672.822510,418.717590 
		C1662.878296,439.583649 1649.844482,458.608490 1634.538330,476.125793 
		C1615.728760,497.652710 1594.242432,515.932983 1569.763550,530.454346 
		C1544.186035,545.627441 1516.799683,556.746338 1487.367554,561.919006 
		C1473.998413,564.268616 1460.444580,565.923340 1446.903320,566.829956 
		C1434.767456,567.642456 1422.454468,567.878601 1410.361694,566.790527 
		C1391.503784,565.093994 1372.861084,561.771240 1354.657593,556.099976 
		C1329.211304,548.172302 1305.465454,536.922729 1283.306030,522.116638 
		C1264.520996,509.565155 1247.721436,494.685577 1232.714722,477.985992 
		C1219.601074,463.393158 1208.508423,447.193420 1198.869507,429.961395 
		C1186.575439,407.982330 1178.127808,384.647247 1172.006226,359.921204 
	M1432.759399,224.041443 
		C1432.759399,287.807465 1432.759399,351.573517 1432.759399,415.501587 
		C1445.755981,415.501587 1458.139038,415.501587 1470.660034,415.501587 
		C1470.660034,326.243225 1470.660034,237.331711 1470.660034,147.841934 
		C1461.653809,147.841934 1452.870117,148.017715 1444.099854,147.751419 
		C1440.804565,147.651367 1438.975586,148.889984 1437.205200,151.451996 
		C1430.146606,161.667191 1422.907959,171.758118 1415.720093,181.883759 
		C1399.800049,204.310638 1383.870605,226.730789 1367.863159,249.268723 
		C1377.548950,256.169586 1387.060059,262.945923 1396.788696,269.877350 
		C1398.069336,268.213287 1399.089844,266.923645 1400.072144,265.605530 
		C1410.679077,251.369370 1421.280151,237.128754 1432.254395,223.091797 
		C1432.254395,223.091797 1432.676147,223.124481 1432.759399,224.041443 
	M1589.695679,253.792770 
		C1572.791626,241.855225 1555.887573,229.917679 1538.299072,217.496780 
		C1538.299072,229.918823 1538.463379,241.077560 1538.207275,252.226654 
		C1538.121094,255.982559 1539.240723,258.227875 1542.401367,260.359009 
		C1557.228882,270.356812 1571.868530,280.633453 1586.565430,290.824860 
		C1592.417969,294.883179 1598.244385,298.978790 1604.421753,303.294220 
		C1583.102783,317.406586 1562.696045,330.992188 1542.177856,344.407196 
		C1539.362061,346.248291 1538.086914,348.144348 1538.212524,351.607788 
		C1538.490356,359.258575 1538.298950,366.926422 1538.298950,374.587311 
		C1538.298950,378.317627 1538.299072,382.047943 1538.299072,386.737946 
		C1549.982300,378.746582 1560.635498,371.482910 1571.264648,364.184479 
		C1592.301025,349.740112 1613.197388,335.086914 1634.400513,320.892120 
		C1647.058472,312.418060 1647.270996,312.735535 1646.969360,297.692230 
		C1646.966064,297.525757 1647.028931,297.326813 1646.959473,297.197479 
		C1646.183228,295.751892 1645.744385,293.821838 1644.542236,292.953583 
		C1626.524658,279.940979 1608.405762,267.068726 1589.695679,253.792770 
	M1329.211304,226.714157 
		C1329.211304,224.642105 1329.211304,222.570068 1329.211304,219.886124 
		C1326.639160,221.396393 1324.742798,222.359192 1323.004761,223.552765 
		C1289.363647,246.656433 1255.732666,269.774902 1222.154663,292.969879 
		C1221.134644,293.674408 1220.165405,295.202667 1220.097168,296.399689 
		C1219.850464,300.718781 1219.860474,305.067841 1220.087036,309.389130 
		C1220.151855,310.626862 1221.016479,312.095947 1221.968628,312.956635 
		C1223.923828,314.724152 1226.161865,316.185852 1228.324951,317.714905 
		C1258.930664,339.349976 1289.537354,360.983948 1320.164917,382.587952 
		C1322.900024,384.517273 1325.782959,386.236877 1329.211304,388.450439 
		C1329.211304,376.324280 1329.031982,365.009827 1329.315674,353.707062 
		C1329.405762,350.117615 1328.251953,347.924713 1325.409058,345.992767 
		C1312.360352,337.124969 1299.388184,328.144684 1286.399658,319.188446 
		C1278.827759,313.967255 1271.276978,308.715240 1263.326294,303.206512 
		C1264.508179,302.245300 1265.082397,301.690979 1265.737183,301.258331 
		C1285.625488,288.117676 1305.485352,274.933228 1325.457397,261.920898 
		C1328.309326,260.062775 1329.380493,258.047638 1329.291626,254.661438 
		C1329.055420,245.667908 1329.211426,236.664093 1329.211304,226.714157 
	z"/>


					</svg>
				</a>


			</div>
		</div>
	);
}