import HeroBanner from '../../components/HeroBanner';
import NavBar from '../../components/NavBar';

export default function Layout({ children, showMenu }) {
  return (
    <>
      <NavBar showMenu={showMenu} />
      <div style={{ overflow: 'hidden', backgroundColor: '#080d1c00' }}>
        <HeroBanner>
          {children}
        </HeroBanner>
      </div>
    </>
  );
}