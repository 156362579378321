export const overlay = {
  hidden: {
    opacity: 0,
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 1,
    }
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 1,
    }
  }
}

export const item = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
    }
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.4,
      delay: 0.5,
      ease: 'linear'
    }
  }
}

export const item2 = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
    }
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.4,
      delay: 0.6,
      ease: 'linear'
    }
  }
}

export const item3 = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
    }
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.4,
      delay: 0.7,
      ease: 'linear'
    }
  }
}

export const item4 = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
    }
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.4,
      delay: 0.7,
      ease: 'linear'
    }
  }
}

export const item5 = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
    }
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.4,
      delay: 0.7,
      ease: 'linear'
    }
  }
}

export const item6 = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
    }
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.4,
      delay: 0.7,
      ease: 'linear'
    }
  }
}