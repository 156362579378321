const ErrorEnum = {
    NOT_FOUND: {
        'status': 404,
        'message': 'Oops! Something is wrong'
    },
    FORBIDDEN: {
        'status': 401,
        'message': 'Unauthorized'
    }
};

export default ErrorEnum;