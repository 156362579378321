import HeroCarousel from '../../components/HeroCarousel';
import Layout from '../../components/Layout';

export default function HomePage() {

  return (
    <Layout showMenu={true}>
      <HeroCarousel />
    </Layout>
  );
}