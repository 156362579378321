import apiBaseUrl from './apiConfig';
import { jwtDecode } from 'jwt-decode';

const accessToken = localStorage.getItem('access_token');

const isAuthenticated = () => {    
  return isAccessTokenValid(accessToken);
};

const isAdmin = () => {  
  const decodedToken = jwtDecode(accessToken);

  return decodedToken.user_type === 'admin';
}

const userData = () => {
  const decodedToken = jwtDecode(accessToken);

  return {
    id: decodedToken.id,
    name: decodedToken.name
  }
}

export { isAuthenticated, isAdmin, userData, accessToken };


// JWT functions
const isAccessTokenValid = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  let decodedToken;
  try {
    decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
  } catch (error) {
    return false; // Invalid token if decryption fails
  }
  
  // Check the expiration date
  if (decodedToken.exp) {
    const expirationTimestamp = decodedToken.exp * 1000;
    const currentTimestamp = new Date().getTime();

    if (currentTimestamp > expirationTimestamp) {
      refreshAccessToken();

      // Will be null when refresh_token couldn't update the access_token
      return localStorage.getItem('access_token') !== null;
    }
  }

  return true;
};

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refresh_token');

  if (refreshToken) {
    try {
      const response = await fetch(`${apiBaseUrl}/refresh-token`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + refreshToken
        },
      });

      if (response.ok) {
        const data = await response.json();
        const access_token = data.access_token;

        localStorage.setItem('access_token', access_token);
      } else {
        localStorage.removeItem('access_token');
      }
    } catch (error) {
      localStorage.removeItem('access_token');
    }
  }
};