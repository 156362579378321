import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence, useCycle } from "framer-motion";
import { h1Variants, h2Variants } from './motion';
import './styles.scss'

const slides = [
  {
    id: 0,
    topic: 'Processamento de Linguagem Natural',
    description: 'Explore nossas soluções impulsionadas por IA que podem compreender e responder à linguagem humana.'
  },
  {
    id: 1,
    topic: 'Análise Preditiva',
    description: 'Antecipe com precisão cenários futuros.'
  },
  {
    id: 2,
    topic: 'Avaliador de Sintomas de Saúde',
    description: 'Descubra como nosso avaliador de sintomas de saúde impulsionado por IA pode ajudar na triagem, consulta e pós-atendimento médico.'
  },
  {
    id: 3,
    topic: 'Machine Learning',
    description: 'Capacite sistemas para aprender e evoluir de forma autônoma.'
  },
  {
    id: 4,
    topic: 'Deep Learning',
    description: 'Construa modelos complexos através de redes neurais profundas.'
  },
  {
    id: 5,
    topic: 'LLM',
    description: 'Capacite máquinas a criar, compreender e interagir com a linguagem de forma intuitiva.'
  },
  {
    id: 6,
    topic: 'Visão Computacional',
    description: 'Crie sistemas para interpretar e compreender visualmente o ambiente com base em visão computacional.'
  },
]

const HeroCarousel = () => {
  const [isMounted, setIsMounted] = useCycle(true, false);
  const [current, setCurrent] = useState(slides[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsMounted(!isMounted);
      setCurrent(() => {
        if (current.id < slides.length - 1) return slides[current.id + 1]
        return slides[0]
      });
    }, isMounted ? 10000 : 3000);

    return () => clearInterval(interval);
  }, [isMounted, current.id, setIsMounted]);

  return (
    <div className='title'>
      <div>
        <AnimatePresence>
          {isMounted &&
            <motion.h1 variants={h1Variants} initial='hidden' animate='visible' exit='exit'>
              {current.topic}
            </motion.h1>}
        </AnimatePresence>
      </div>
      <div className='slide-description'>
        <AnimatePresence>
          {isMounted &&
            <motion.h2 variants={h2Variants} initial='hidden' animate='visible' exit='exit'>
              {current.description}
            </motion.h2>}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default HeroCarousel;