import { useCallback, useState } from 'react';
import LogoCisp from '../LogoCisp';
import './styles.scss'
import Image2 from '../../assets/recommender.webp'
import Image1 from '../../assets/healthcare.webp'
import Image3 from '../../assets/price.webp'
import Image4 from '../../assets/digital_human.webp'
import Image5 from '../../assets/face_recognition.webp'
import Image6 from '../../assets/object_detection.webp'
import { motion, useAnimationControls, AnimatePresence } from 'framer-motion'
import { overlay, item, item2, item3, item4, item5, item6 } from './motion';
import { MenuButton } from '../MenuButton';


export default function NavBar({ showMenu }) {
  const controls = useAnimationControls()
  const [show, setShow] = useState(false)
  const [isOpen, setOpen] = useState(false);

  const start = useCallback(() => {
    controls.start({
      height: '400px',
      transition: {
        duration: 0.7,
        ease: 'easeInOut'
      }
    });
    setShow(true)

  }, [controls]);

  const end = useCallback(() => {
    controls.start({
      height: '70px',
      transition: {
        duration: 0.7,
        ease: 'easeInOut'
      }
    });
    setShow(false)
  }, [controls]);

  const handleMenu = async () => {
    if (!isOpen) {
      setOpen(true)
      await controls.start({
        height: '400px',
        transition: {
          duration: 0.3,
          ease: 'easeInOut'
        }
      });
      setShow(true)
    } else {
      controls.start({
        height: '70px',
        transition: {
          duration: 1,
          ease: 'easeInOut'
        }
      });
      setOpen(false)
      setShow(false)

    }
  }

  return (
    <div>

      <AnimatePresence>
        {isOpen && <motion.div
          variants={overlay}
          initial='hidden'
          animate='visible'
          className='overlay'></motion.div>}
      </AnimatePresence>

      <motion.div
        animate={controls}
        onHoverEnd={!isOpen ? end : false}
        className='navbar'
      >
        <div className='header-top-wrapper'>
          <div className='header-top-frame'>
            <LogoCisp animation={true} />
          </div>
          {showMenu && <div className='header-top-frame'>
            <MenuButton
              isOpen={isOpen}
              onClick={handleMenu}
              className='hamb-menu'
            />
            <motion.div onHoverStart={start} className='text-menu'>
              <span>Showcases</span>
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path>
              </svg>
            </motion.div>
          </div>}
        </div>

        <AnimatePresence>
          {show &&
            <motion.div className='header-container'>
              <a className="menu-link" href="/recommender">
                <motion.div
                  variants={item}
                  initial='hidden'
                  animate='visible'
                  whileHover={!isOpen ? { scale: 1.1 } : null}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                  exit='exit'
                  className='header-item'
                >
                  <div className='img-container'>
                    <motion.img
                      width='200'
                      src={Image2}
                      alt='recommender system showcase'
                    />
                  </div>

                  <span>Sistema de Recomendação por IA</span>
                </motion.div>
              </a>

              <a className="menu-link" href="/triage">
                <motion.div
                  variants={item2}
                  initial='hidden'
                  animate='visible'
                  whileHover={!isOpen ? { scale: 1.1 } : null}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                  exit='exit'
                  className='header-item'
                >
                  <div className='img-container'>
                    <motion.img
                      width='200'
                      src={Image1}
                      alt='medical triage showcase'
                    />
                  </div>
                  <span>Triagem Médica por IA</span>
                </motion.div>
              </a>

              <a className="menu-link" href="/digital-human">
                <motion.div
                  variants={item4}
                  initial='hidden'
                  animate='visible'
                  whileHover={!isOpen ? { scale: 1.1 } : null}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                  exit='exit'
                  className='header-item'
                >
                  <div className='img-container'>
                    <motion.img
                      width='200'
                      src={Image4}
                      alt='digital human showcase'
                    />
                  </div>
                  <span>Humano Digital por IA</span>
                </motion.div>
              </a>

              <a className="menu-link" href="/face-recognition">
                <motion.div
                  variants={item5}
                  initial='hidden'
                  animate='visible'
                  whileHover={!isOpen ? { scale: 1.1 } : null}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                  exit='exit'
                  className='header-item'
                >
                  <div className='img-container'>
                    <motion.img
                      width='200'
                      src={Image5}
                      alt='face recognition showcase'
                    />
                  </div>
                  <span>Reconhecimento Facial</span>
                </motion.div>
              </a>

              <a className="menu-link" href="/object-detection">
                <motion.div
                  variants={item6}
                  initial='hidden'
                  animate='visible'
                  whileHover={!isOpen ? { scale: 1.1 } : null}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                  exit='exit'
                  className='header-item'
                >
                  <div className='img-container'>
                    <motion.img
                      width='200'
                      src={Image6}
                      alt='object detection showcase'
                    />
                  </div>
                  <span>Detecção de Pessoas e Objetos</span>
                </motion.div>
              </a>
            </motion.div>}
        </AnimatePresence>


      </motion.div>
    </div>
  );
}