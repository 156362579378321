import React from "react";
import styles from './styles.module.scss';

export default function ErrorPage({ statusError, messageError }) {
  return (
    <div className={styles.errorMain}>
      <h1 className={styles.statusError}>{statusError}</h1>
      <p className={styles.messageError}>{messageError}</p>
      <a className={styles.errorButton} href="/">Go back in initial page</a>
    </div>
  )
}