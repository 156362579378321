import MainVideo from '../../assets/bg.mp4'
import './styles.scss'

export default function HeroBanner({ children }) {
  return (
    <section>
      <video playsInline muted loop autoPlay style={{ position: "absolute" }}>
        <source src={MainVideo} type="video/mp4" />
          Your browser does not support HTML5 video.
      </video>
      <div className='overlay'></div>
      {children}
    </section>
  );
}