import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter, Navigate , useLocation } from 'react-router-dom';
import './reset.scss';

import ErrorEnum from './utils/enums/ErrorEnum';

import HomePage from './pages/home';
import ErrorPage from './pages/error';

import { isAuthenticated, isAdmin } from './utils/auth';

const RecommenderPage = React.lazy(() => import('./pages/recommender'));
const MedicalPage = React.lazy(() => import('./pages/medical'));
const LoginPage = React.lazy(() => import('./pages/login'));
const DigitalHumanPage = React.lazy(() => import('./pages/digital-human'));
const DigitalHumanStreamPage = React.lazy(() => import('./pages/digital-human-stream'));
const FaceRecognitionPage = React.lazy(() => import('./pages/face-recognition'));
const FaceRecognitionResultsPage = React.lazy(() => import('./pages/face-recognition/results.js'));

const ObjectDetectionPage = React.lazy(() => import('./pages/object-detection'));
const ObjectDetectionResultsPage = React.lazy(() => import('./pages/object-detection/results.js'));

const HomeAdminPage = React.lazy(() => import('./pages/home-admin'));
const AdminLogsPage = React.lazy(() => import('./pages/admin-logs'));

function PrivateRoute({ children }) {
  const location = useLocation();
  const { pathname } = location;

  localStorage.setItem('redirect_after_login', pathname);

  return isAuthenticated() ? <>{children}</> : <Navigate to="/login" />;
}

function AdminRoute({ children }) {
  const location = useLocation();
  const { pathname } = location;

  localStorage.setItem('redirect_after_login', pathname);

  if (!isAuthenticated())
    return <Navigate to="/login"/>;

  return isAdmin()
    ? <>{children}</>
    : <ErrorPage statusError={ErrorEnum.FORBIDDEN.status} messageError={ErrorEnum.FORBIDDEN.message}/>;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<HomePage />} />
      
      <Route path='/login' element={isAuthenticated() ? <Navigate to="/"/> : <LoginPage />} />
      
      <Route path='/recommender' element={<PrivateRoute> <RecommenderPage/> </PrivateRoute>} />  
      
      <Route path='/triage' element={<PrivateRoute> <MedicalPage/> </PrivateRoute>} />
      
      <Route path='/digital-human' element={<PrivateRoute> <DigitalHumanPage/> </PrivateRoute>} />
      <Route path='/digital-human-stream' element={<PrivateRoute> <DigitalHumanStreamPage/> </PrivateRoute>} />
      
      <Route path='/face-recognition' element={<PrivateRoute> <FaceRecognitionPage/> </PrivateRoute>} />
      <Route path='/face-recognition/results/:requestId' element={<PrivateRoute> <FaceRecognitionResultsPage /> </PrivateRoute>} />

      <Route path='/object-detection' element={<PrivateRoute> <ObjectDetectionPage/> </PrivateRoute>} />
      <Route path='/object-detection/results/:requestId' element={<PrivateRoute> <ObjectDetectionResultsPage/> </PrivateRoute>} />
      
      <Route path='/admin/dashboard' element={<AdminRoute> <HomeAdminPage/> </AdminRoute>} />
      <Route path='/admin/logs' element={<AdminRoute> <AdminLogsPage/> </AdminRoute>} />

      {/* <Route path='*' element={<ErrorPage />} /> */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </BrowserRouter>
);
