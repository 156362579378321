export const h1Variants = {
  hidden: {
    opacity: 0,
    x: -125,
  },
  exit: {
    opacity: 0,
    x: 150,
    transition: {
      duration: 2,
      ease: 'easeInOut'
    }
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 2,
      ease: 'easeInOut'
    }
  }
}

export const h2Variants = {
  hidden: {
    opacity: 0,
    x: -150,
  },
  exit: {
    opacity: 0,
    x: 125,
    transition: {
      duration: 2,
      ease: 'easeInOut'
    }
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 2,
      delay: 0.1,
      ease: 'easeInOut'
    }
  },
}